var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_vm.$options.components['bill-payment-category'] ? [_c('bill-payment-category')] : _vm._e()], 2), _c('b-col', {
    attrs: {
      "md": "9"
    }
  }, [_c('span', {
    staticClass: "text-grey1"
  }, [_vm._v("Bill Payment > DTH Payment")]), _c('b-row', {
    staticClass: "mt-4"
  }, [_c('b-col', {
    attrs: {
      "md": "7"
    }
  }, [_c('div', {
    staticClass: "m-4"
  }, [_c('h5', [_vm._v("Consumer Number: " + _vm._s(_vm.$route.params.number))]), _c('div', {
    staticClass: "d-flex justify-content-between mt-4 mb-3"
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v("Plan: Basic")])]), _c('v-text-field', {
    attrs: {
      "label": "₹ Enter Amount Wish to Pay"
    },
    model: {
      value: _vm.amount,
      callback: function callback($$v) {
        _vm.amount = $$v;
      },
      expression: "amount"
    }
  }), _c('button', {
    staticClass: "btn btn-primary w-100 mt-4",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.gotocheckout
    }
  }, [_vm._v(" Continue ")])], 1)])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }