<template>
  <b-container>
    <b-row>
      <b-col md="3">
        <template v-if="$options.components['bill-payment-category']">
          <bill-payment-category />
        </template>
      </b-col>
      <b-col md="9">
        <span class="text-grey1">Bill Payment > DTH Payment</span>
        <b-row class="mt-4">
          <b-col md="7">
            <div class="m-4">
              <h5>Consumer Number: {{ $route.params.number }}</h5>
              <div class="d-flex justify-content-between mt-4 mb-3">
                <p class="mb-0">Plan: Basic</p>
                <!-- <router-link to class="change-plan-ancher"
                  >Select Plan</router-link
                > -->
              </div>
              <v-text-field
                label="₹ Enter Amount Wish to Pay"
                v-model="amount"
              ></v-text-field>
              <button
                type="button"
                @click="gotocheckout"
                class="btn btn-primary w-100 mt-4"
              >
                Continue
              </button>
            </div>
          </b-col>
          <!-- <b-col md="5 mb-4">
            <div>
              <h5 class="mb-4">Upcoming Action</h5>
              <template
                v-if="$options.components['bill-payment-action-transaction']"
              >
                <bill-payment-action-transaction />
              </template>
            </div>
            <div>
              <h5 class="mt-4 mb-4">Recent Transaction</h5>
              <template
                v-if="$options.components['bill-payment-action-transaction']"
              >
                <bill-payment-action-transaction />
              </template>
            </div>
          </b-col>
          <b-col md="12" class="mt-4 latest-offer-div">
            <h4 class="mb-4 mt-3">Latest Offers</h4>
            <template v-if="$options.components['bill-payment-latest-offers']">
              <bill-payment-latest-offers />
            </template>
          </b-col>-->
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  name: "dth-pay",
  data() {
    return {
      amount: "",
    };
  },
  methods: {
    gotocheckout() {
      this.$router.push({
        name: "dth-checkout",
        params: { amount: this.amount, m_id: this.$route.params.ca_id },
      });
    },
  },
  async mounted() {
    this.amount = this.$route.params.amount;
    const payload = this.$route.params.ca_id;
    await this.$store.dispatch("billPayment/getCategory", payload);
  },
};
</script>
<style scoped>
.change-plan-ancher {
  font-size: 14px;
  text-decoration: underline;
  color: #ff6a2e;
  font-weight: 500;
}

.latest-offer-div {
  background-color: #f8f8f8;
}
</style>
